import React, { useEffect, useState } from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { Faq } from '../../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { POKER_FAQS, POKER_FAQS_POKER_PAYMENTSBONUSES } from '../../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'
import PokerFAQs from '../../../components/FAQs/PokerFAQ'
import { pokerfaqs } from '../../../components/FAQs/faqs-list'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Poker FAQs',
    url: POKER_FAQS,
  },
  {
    title: 'Payments & Bonuses',
    url: POKER_FAQS_POKER_PAYMENTSBONUSES,
  },
]

const FAQs: React.FC = () => {
  const [cardsFaqsSt, setCardsFaqsSt] = useState<Faq[]>([])
  const [pokerFaqsSt, setPokerFaqsSt] = useState<Faq[]>([])
  const [rummyFaqsSt, setRummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../components/FAQs/faqs-list').then(
      ({ cardsFaqs, pokerFaqs, rummyFaqs }) => {
        setCardsFaqsSt(cardsFaqs)
        setPokerFaqsSt(pokerFaqs)
        setRummyFaqsSt(rummyFaqs)
      }
    )
  }, [])

  const faqs = [...cardsFaqsSt, ...pokerFaqsSt, ...rummyFaqsSt]

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Cards FAQ: Read Frequently Asked Questions on Card Games | Mega"
        description="Read frequently asked questions on card games in Mega. Find answers to queries on texas hold'em poker & rummy gameplay, rules and winnings. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(faqs)}
      />
      <PokerFAQs title="FAQs About Poker Payments & Bonuses" index={1} pokerfaqs={pokerfaqs} />
    </Layout>
  )
}

export default FAQs
